@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;
  padding: var(--modalPadding);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 1;
    min-height: max(100%, 100vh);
    height: auto;
    padding: 24px;
    background-color: var(--colorWhite);
    border-bottom: none;
  }
}

.modalContent {
  composes: marketplaceModalContentStyles from global;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 24px;
  margin-bottom: 16px;
  color: var(--marketplaceColor);
}

.message {
  composes: marketplaceModalParagraphStyles from global;
  margin-bottom: 24px;
}

.button {
  composes: buttonPrimary from global;
  margin-top: 16px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: var(--marketplaceColorLight);
  border-radius: 50%;
}

.icon {
  width: 30px;
  height: 30px;
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>');
  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>');
  background-color: white;
}
