:root {
  --ukreatePurple: #500543;
}

#active-topLink {
  border-bottom: 3px solid #500543;
}

/* Start About Page */

.bannerWrap {
  padding: 60px;
  text-align: center;
  margin: 0 30px 30px;
  border-radius: 80px;
  background-position: center;
}

.bannerContWrap {
  max-width: 680px;
  margin: 0 auto;
}

.aboutBannerTitle {
  color: #fff;
  margin: 0;
}

.bannerContWrap p {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin: 10px 0 0;
}

p.aboutLabel {
  border-radius: 100px;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  background: #ffffff33;
  padding: 16px 24px;
  margin-bottom: 32px;
}

.whyExitWrap {
  padding: 60px 0 80px;
}

.whyExitWrap .container {
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
}

.whyExitContWrap {
  max-width: 800px;
  margin: 0 auto;
}

.whyExitTitle,
.aboutBannerTitle {
  font-size: 48px;
  line-height: 56px;
}

.whyExitTitle {
  color: #191a1f;
  margin: 0 0 50px;
  text-align: center;
  padding: 0;
}

.whyExitText {
  font-size: 20px;
  line-height: 30px;
  color: #191a1f;
}

.whyExitabelRed {
  background: #e811011a;
  color: #e81101;
}

.whyExitabelRed,
.whyExitabelBlue,
.whyExitabelPink,
.whyExitabelYellow {
  padding: 8px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  margin-bottom: 5px;
}

.whyExitabelRed svg,
.whyExitabelBlue svg,
.whyExitabelPink svg,
.whyExitabelYellow svg {
  fill: transparent;
}

.whyExitabelBlue {
  background: #4952f71a;
  color: #4952f7;
}

.whyExitabelPink {
  background: #ff52951a;
  color: #ff5295;
}

.whyExitabelYellow {
  background: #e08e001a;
  color: #e08e00;
}

.rootMission {
  background: #f3f4f2;
  padding: 100px 0;
}

.missionContainer,
.accept-container {
  min-height: unset;
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;

  @media (--viewportXLarge) {
    max-width: var(--contentLgMaxWidthPages);
  }

  @media (--viewportMedium) {
    min-height: 100%;
  }

  @media (--viewportMediumScreen) {
    max-width: 100%;
    padding: 0 60px 80px;
  }

  @media (--viewportXSScreen) {
    padding: 0 20px 56px;
  }
}

.missionLabel,
.founder-label {
  font-size: 14px;
  color: #191a1f;
  line-height: 20px;
  font-weight: 500;
  background-color: #d3b5f2;
  padding: 12px 16px;
  border-radius: 50px;
}

.missionHeading,
.founder-heading {
  text-align: center;
  margin: 30px 0 46px;
  font-size: 25px;
  color: #191a1f;
  padding-top: unset;
  font-family: 'Lexend', sans-serif;
  text-align: left;
  width: 80%;
  font-weight: 800;

  @media (--viewportXMScreen) {
    font-size: 36px;
    line-height: 46px;
  }

  @media (--viewportLarge) {
    font-size: 48px;
    line-height: 1.2;
  }

  @media (--viewportXSScreen) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (--viewportFinalSmallScreen) {
    font-size: 29px;
    line-height: 38px;
  }

  /* margin-bottom: -4rem; */
}

.missionContWrap p,
.founder-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #191a1f;
  margin: 0;

  @media (--viewportXMScreen) {
    font-size: 14px;
    line-height: 20px;
  }
}

.missionIconContWrap {
  align-items: center;
  display: flex;
  gap: 24px;
}

.missionRightImg,
.missionContWrap {
  @media (--viewportXMScreen) {
    width: 50%;
  }

  @media (--viewportMSScreen) {
    width: 100%;
  }
}

.missionRightImgFinal {
  @media (--viewportXMScreen) {
    width: 100%;
  }
}

.founder-outer-wrap {
  padding: 100px 0;
}

.founder-container {
  margin: 0 auto;
  max-width: var(--contentMaxWidthPages);
}

.founder-wrap {
  display: flex;
  align-items: center;
  gap: 24px;
}

.founder-follow-card {
  align-items: center;
  background: #f3f4f2;
  border-radius: 20px;
  display: flex;
  gap: 20px;
  max-width: 300px;
  padding: 16px;
  margin-top: 56px;
}

.founder-insta-cont p {
  font-size: 14px;
  color: #191a1f80;
  margin: 0;
  font-weight: 600;
}

.founder-insta-arrow {
  flex: 1;
  text-align: right;
}

p.founder-text a {
  text-decoration: underline;
  color: #191a1f;
}

.main_wrapper {
  margin: 20px 0px;
}

.main_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  background: #f3f4f2;
  border-radius: 80px;
}

.btn_text.commitment-card {
  width: 500px;
  padding: 30px 20px;
  gap: 16px;
}

.commitment-card {
  background: #fff;
  border-radius: 25px 25px 0px 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 28px 22px;
  width: 260px;
  position: relative;
  margin-bottom: 80px;
}

.commitment-card p {
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: #191a1f;
}

.boder_bt {
  background: #fff;
  border-radius: 25px 25px 25px 0px;
}

.all_data {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 70px;
}

.commi_sec h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  margin-top: 0;
  padding: 0;
  color: #191a1f;
}

.chat_one.commitment-card p,
.btn_text p {
  color: #191a1f;
}

.chat_one.commitment-card {
  position: relative;
}

.chat_one:before {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAABECAYAAAD+1gcLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH9SURBVHgB7ZnrTcNAEIT3qCAluIRQAaECoAKgg9ABdEAHiAqACmIqIKkAdxA6MLPRHXJOtvHjHrtSPml11jk/xuO5hy+GQF3XSzQF6hp1Ya9FY9o68SArNHeoWxKK6buJByjQrFFXJOwtmCE/sg/wSILewCDhDvsAL6gVZeZszI+NMRXqEpf3qB/KyCjHm1j331BLysAox5tY989x+URagftr1L5OyOSotIgv0Gwo0bQZTDiTUnxQ4Uwq8ZMHZxc8aNHwlLmliAR33AHnF2i+KJLzwR13wHleoNj5iiIQzXFHrMxHF85Y8RybBQUiWlSa2AF7QwFJIpyB+BLNA2kFsXmuA5Ak4y3iOe+zdpXJouLBeZ+1n88iPMZgTcqcvGfJuCd+Ut5zZbzJpLxnF27zrvrzbzMm4yQFaFmpFM6McZ0kMcZ1ksZQ10kaQ10niQxxXcIC1MYHaQSGLup/jvREOm5PCNS63jtIs+8O++C4UMfJgNTB6XjtuiFd+HvXDdFRYbriIt1xpnV20SC8bOvUEBWOyd7vF++4XYxKv19DVJhPv0OL8JK04m+6tDjOHP0Zpkn40Xyu1nE1+B8Xahy38/mf65qiwuzchTbhJWkE0S5En6v0Ac3f2hYgx2GAahR+2HBpFF6RRtwAJY3wCqoxKsxWq/DdyfHE6N3i/gIh3gZn3uOR3wAAAABJRU5ErkJggg==');
  z-index: 1;
  position: absolute;
  right: 0px;
  bottom: -70px;
}

.chat_one_left:before,
.chat_one_right:before {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAmCAYAAACRWlj1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE4SURBVHgB3djxTYNQEMfxH53AERilbuIIbmCcoHEC6wTGCcQJZAOeE+gGP+/ySnwiWFoovLtPckn/adJvuAekIHkFxzYyJRzTwC08kxV9hWcS+On5HOqKatwNvGLkd035YwuPkkCfV5G/3cKbTqC/Oyr/eoYn7OdnVQcCdVVLeMBhjYvzyP/Zf3TwuB0s4zh3sIrj2YzkaexF8nS2InmeR1p5hPB877TwMsBpmtwjN5imlNHIfN9dOZ8dMzyXhf4yzCfIXBdFEZCJqSvaVSKubD6PEl5Owwz+yJp7RfvsZZ5kbSusYInAVoUYuseClgxsBcTY+yVuRmsEpmqZB5nqUrFrB6Y0tpJ5mfO85hSY+sIhFhOvbq6BXQHxCmtwLcH12C9aCezSK1wf5k0mDEVbDeyTRn+0n78BBCpnu7wSNeMAAAAASUVORK5CYII=');
  z-index: 1;
  position: absolute;
  right: -50px;
  bottom: -7px;
}

.chat_two:after {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAABECAYAAAARFGw1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHgSURBVHgB3drtTcMwEMbxJ4gBWKUjMEJHYAM6Ac0GdIN2g7KBu0HYwCNkg+BDtZRaebHjt7v+JIsP8OGv08WFlmYYhiOAL/CnzLndv+qmafQreOvMuZhzNrG9+02u8cqc1gSrpR/iFk+TPqxFWy/ggVaCone+4YTD5JU5H/QAIlDNydtpv28JJ7Umr83Zm+gOEWpMXpmziw0npeNP9zXpkUDJeLq3P5FQqXgKPyKxEg8s3SjfyCD35Ntc4SRnfJZVGcsVnz2c5Ig/lQgnqeNV6utwScp4bc4eBaWK1+Yke+X0lSr+sPU3wxgp4ulmuaKC2Piu1M0yJSZeo/AD6oqJb2vs+djWeHof5YzKtsRrcw5gYEt8W/o+nxMaf+WwLlZoPIt1sULiz7VvF1dIfAtmfOMVt6kT3/gLGPKJ15xumDGfeAWmfOLZPajWWjzLB9Vai2f5oFqrkwdjS/GsV4Ysxf+AuaX4Kn9Uh5iL77ivDJmLv0GAuXj2K0Mm40M+ha5pKl5BiKl49lekNRUf/eFuKW58L2XfiRsvZurEjRdxv1tuvIIgT7M2HZf3IH09xEOYcbyoh5U8x+RT/NtUaTZeQSAbryGQjf+FQDZe3L4T8fG9tFdWi+JFTv3fMAxvEOoPyIVaylFt1agAAAAASUVORK5CYII=');
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: -69px;
  left: 0;
}

.chat_one_lef:after,
.chat_one_righ:after {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAmCAYAAAB+mDPLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFeSURBVHgB7ZntrcIwDEWtpzcAI2QDugHZAEboBrABbAAbMAojMFLxBVeyoKUflDS2OFLr0ubP0TVJA0Q/7FNVVfFH/vEtiRS5BO9Jljh5l1zj5FaSW7XkEnDtOcl9feFSUqcIvCa51x/cSXKKEAz6nitJFgxcDs/33Uiy4ILLpemZpyRf2rTGhSSnuOWya3tuXlLeT0/vxpiWlInm0jXOrKQSXHSNNSmpBEOf8eYkhwoCU5IyyQwSBGYkWTDSCEFgQlLWwV6TTBPZS7LgkTrWwS7+KVNkgjnzEelDskxSff8iTUB2ktKeoyaYNrJpV1ke0J4FTczsSWIfKLv5K31BEMyWpGxy6y3SqKWhL8klU8rVJJEUMbQi2jJSYr4mqcTwU31JiVJrYlJJEdvwsZI6m5jmI8mntFAjZcggSZGK9EiqkCOLtN7RKqlSwrGkh1wgg9wl5WX4/q8sGRdq4gYq/X8v77Z4BgAAAABJRU5ErkJggg==');
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: -7px;
  left: -53px;
}

.btn_text:after {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHAAAAAmCAYAAAAcNx+hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH0SURBVHgB7ZvtbcIwEIYvnaAjZIOyAekE7QZlg3YEOgFsQDegnQCYAJgg2YBu8PZOlwpEFZV8ObZzj/Qq/AAh+dHZPmOIIgfAPUXMHUUMy5vwI6WIiVogIwKnZIQJV+CasyEjPGTtg3KKeR2MeQp9Lp8i74WMsOCqy3HGptGQYGEz/CUjIwyuqs+qMCQqqu+XNzL8hQWlFdWHMexIg4flrPA/azL8Q6ZH3I5NpT5RTp2nGgLlvSkZwwM9cclRnxy2Hg4P9LyzKdZaDAkLWKI9CzLcwwM/R3fMyXBHx/JMokt6kmcSXYBu1jyT6Bpoq/ABd6xgLUY3QJv0Pdwj35mS0RwewAmaNeldkcMkNgN6tlnneKxP7Oz0VqDrnYvNSl0W8HBdTMgjoBdx5eeelPyk4DwmSVKQJ3hzKw26fd+T3zepU04OazXO8GBkGGaX2ZYcY74oBRW3QfisMCaRiEfcNRuO8wvETjYx0F5qxnklvSkdMwVny3l3sdnpTWAp7Yn0intG4+TAWXJ2fcnsVCB0LZBMabzSqhCZW84Xy9xSR7QSeFFl0r9JpdkB8G18k8r8pJbVWUsgtNGW6pJnRpH/+9UhBWmFitAjCz3c+sFKgWV1ZZwHUmESqzA3SIUeyuw4RZXUBHq+l5IKMln+cim14Bzl9Q+NZ6o2daXaGAAAAABJRU5ErkJggg==');
  z-index: 1;
  position: absolute;
  right: 0;
  /* bottom: 0; */
  left: 0%;
  top: -38px;
}

.img_sec img {
  width: 90%;
  margin: auto;
  align-items: center;
  display: flex;
}

.btn_text {
  border-radius: 25px;
  width: 500px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.btn_text button {
  background: black;
  color: #fff;
  padding: 10px 50px;
  font-size: 16px;
  border-radius: 40px;
  margin-top: 20px;
}

.btn_icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 15px;
  text-align: center;
  margin-top: 32px;
  background: #191a1f;
  color: #fff;
  padding: 8px 32px;
  border-radius: 48px;
  border: 1px solid #191a1f;
  transition: 0.3s all ease-in-out 0s;
}

.btn_icon:hover img {
  filter: invert(1);
}

.btn_icon:hover {
  background: transparent;
  color: #191a1f;
  transition: 0.3s all ease-in-out 0s;
  text-decoration: none;
}

.mobile-responsive {
  display: none;
}

/* ShowCase Section */
.showcase-container {
  background-color: #fff;
  min-height: unset;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;

  @media (--viewportXLarge) {
    max-width: var(--contentLgMaxWidthPages);
    padding: 0;
  }

  @media (--viewportMedium) {
    min-height: 100%;
    max-width: unset !important;
  }

  @media (--viewportFinalSmallScreen) {
    padding: 0 20px;
  }
}

.showCaseWrap {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.showCaseCard {
  padding: 16px 32px 32px;
  border-radius: 64px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
  }

  @media (--viewportXSScreen) {
    gap: 32px;
    border-radius: 20px;
    padding: 16px;
  }
}

.showCaseCont {
  margin: 120px auto 0;
  max-width: 75%;

  @media (--viewportLarge) {
    max-width: 90%;
  }

  @media (--viewportMSScreen) {
    width: 100%;
  }

  @media (--viewportXSScreen) {
    margin: 30px auto 0;
    max-width: 100%;
  }
}

.showCaseTitle {
  font-size: 48px;
  line-height: 56px;
  font-weight: 800;
  text-align: center;
  margin: 0 0 10px;
  padding: 0;

  @media (--viewportLarge) {
    font-size: 48px;
    line-height: 1.2;
  }

  @media (--viewportXMScreen) {
    font-size: 36px;
    line-height: 46px;
  }

  @media (--viewportXSScreen) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (--viewportFinalSmallScreen) {
    font-size: 28px;
    line-height: 38px;
  }
}

.showCaseContent {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  text-align: center;

  @media (--viewportXSScreen) {
    font-size: 14px;
  }
}

.showCaseButton {
  background-color: #f3f4f2d9;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 32px 4px 4px;
  border-radius: 48px;
  font-size: 16px;
  color: #191a1f;
  line-height: 20px;
  font-weight: 500;
  transition: 0.3s all ease-in-out 0s;

  @media (--viewportXSScreen) {
    font-size: 15px;
  }
}

.showCaseButton:hover {
  text-decoration: none;
  background-color: #fff;
  transition: 0.3s all ease-in-out 0s;
}

.showCaseButton:hover .showCaseButtonIcon {
  background-color: #f3f4f2d9;
}

.showCaseButtonIcon {
  background-color: #fff;
  padding: 10px;
  border-radius: 48px;
}

.showCaseImage {
  width: 100%;
}

.showCaseImageFinal {
  width: 100%;
  object-fit: cover;
}

.footer-text-wrap {
  padding-top: 80px;
}

.final_ftr-text {
  text-align: center;
}

.final_ftr-text h2 {
  font-size: 48px;
  line-height: 56px;
  margin: 0;
  padding: 0;
  color: #500543;
}

h2.ftr-text-clr {
  color: #ff5295;
}

/* Start resource page */
.resourceBannerContWrap {
  max-width: 600px;
}

.learn-grow-container {
  /* background-color: #fff8fe; */
  min-height: unset;
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;

  @media (--viewportXLarge) {
    max-width: var(--contentLgMaxWidthPages);
  }

  @media (--viewportMedium) {
    min-height: 100%;
  }

  @media (--viewportMediumScreen) {
    max-width: 100%;
    padding: 0 60px;
  }

  @media (--viewportFinalSmallScreen) {
    padding: 0 20px;
  }
}

.learn-grow-wrap {
  padding: 80px 0;
}

.resourceLearnTitle {
  margin: 0 0 56px;
  padding: 0;
  font-size: 48px;
  line-height: 56px;
  font-weight: 800;
  color: #191a1f;

  @media (--viewportLarge) {
    font-size: 48px;
    line-height: 1.2;
  }

  @media (--viewportXMScreen) {
    font-size: 36px;
    line-height: 46px;
  }

  @media (--viewportXSScreen) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (--viewportFinalSmallScreen) {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 32px;
  }
}

.resourceLearnGrowWrap {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (--viewportXSScreen) {
    flex-direction: column;
  }
}

.resourceLearnGrowCard {
  background-color: #f3f4f2;
  border-radius: 32px;
  padding: 8px;

  @media (--viewportLarge) {
    width: 50%;
  }
}

.resourceLearnGrowImgFinal,
.resourceLearnGrowImg {
  width: 100%;
}

.resourceLearnGrowContent {
  padding: 24px;
}

.learnGrowLabel,
.learnGrowLabelBlue {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.learnGrowLabel {
  color: #e08e00;
}

.learnGrowLabelBlue {
  color: #4952f7;
}

.resourceLearnGrowContWrap {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.resourceLearnGrowTitle {
  font-size: 20px;
  line-height: 24px;
  color: #191a1f;
  font-weight: 500;
  margin: 0 0 5px;
  padding: 0;

  @media (--viewportFinalSmallScreen) {
    font-size: 16px;
    line-height: 22px;
  }
}

.resourceLearnGrowCaption {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #191a1f;
  padding: 0;
  margin: 0;
}

.resourceLearnGrowButton {
  padding: 12px 32px;
  border-radius: 48px;
  background-color: #191a1f;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 15px;

  @media (--viewportFinalSmallScreen) {
    font-size: 15px;
  }
}

.tablink {
  background-color: #f3f4f2;
  color: #000000;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 11px 25px;
  font-size: 14px;
  width: fit-content;
  border-radius: 50px;
  font-weight: 500;
  margin-left: 16px;
}

.tablink:hover {
  background-color: #191a1f;
  color: #f3f4f2;
}

.container {
  width: 1200px;
  margin: auto;
  padding: 20px;
}

.Resources-wrapper .heading h2 {
  font-size: 48px;
  line-height: 65px;
  color: #191a1f;
  font-weight: 800;
  margin-top: 0;
}

.Resources-wrapper.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: var(--contentMaxWidthPages);
  padding: 0px 15px 20px;
}

.tabs-name {
  width: 760px;
  margin: auto;
  overflow: hidden;
  text-align: center;
}

.all-data {
  background: #f3f4f2;
  border-radius: 20px;
  padding: 10px;
  flex: 0 0 calc(33% - 10px);
}

.final-resource-wrap .all-data .iner p {
  color: #191a1f;
  font-size: 14px;
  line-height: 20px;
  margin: 16px 0 0;
}

.inner-fisrt {
  display: flex !important;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 50px;
  /* justify-content: center; */
}

.tabcontent-you img {
  width: 100%;
}

.tabcontent-you h3 {
  color: #500543;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;
  padding: 0;
}

.tabcontent-you h2 {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  min-height: 86px;
  margin-top: 0;
}

.tabcontent-you p {
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  color: #191a1f99;
}

.iner {
  padding: 12px;
}

.date {
  display: flex;
  justify-content: space-between;
}

button.tablink.active {
  background: #191a1f;
  color: #fff;
}

/* End Resource page */

/* Start Pricing Page */

.pricing-banner-wrap {
  padding: 80px 0 0;
  margin: 0 30px;
}

.pricing-banner-cont-wrap {
  width: 65%;
  margin: 0 auto;
  text-align: center;
}

.pricing-banner-cont-wrap h1 {
  font-size: 48px;
  line-height: 56px;
  color: #191a1f;
  font-weight: 800;
}

.pricing-grid {
  display: flex;
  gap: 20px;
  margin-top: 36px;
}

.tabtop {
  background-color: #f3f4f2;
  color: #191a1f;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 16px 25px;
  font-size: 16px;
  width: 47%;
  border-radius: 50px;
  font-weight: 600;
}

.tabtop.active,
.tabtop:hover {
  background-color: #191a1f;
  color: #fff;
}

.container {
  width: 1200px;
  margin: auto;
  padding: 20px;
}

.Resources-wrapper.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabs-block {
  width: 45%;
  margin: auto;
  overflow: hidden;
  background: #f5f6f4;
  border-radius: 50px;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.tabs {
  width: 100%;
}

.all-data-pricing {
  width: 49%;
  background: #f3f4f2d9;
  border-radius: 25px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  gap: 40px;
}

.doller {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 22px;
}

h3.top-head {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 8px;
  line-height: 24px;
  text-transform: none;
}

.month {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.month span {
  font-size: 48px;
  font-weight: 800;
  line-height: 56px;
}

.premium .month {
  color: #f5f6f4cc;
}

.free .month {
  color: #191a1f80;
}

.premium .month span {
  color: #fff;
}

.free .month span {
  color: #000;
}

.premium-head {
  color: transparent !important;
  background-clip: text !important;
  background: linear-gradient(90deg, #986ff0 0%, #ff827e 15.02%, #ffc24f 28.57%);
}

h3.top-head.free-head {
  color: #000;
}

.all-data-pricing.free p {
  color: #000;
}

.icon-box h3 {
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: black;
}

.doller-btn {
  width: 100%;
  background: black;
  color: #fff;
  border-radius: 50px;
  padding: 14px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid black;
  text-align: center;
}

.doller-btn:hover {
  background: transparent;
  color: black;
  transition: 0.3s all ease-in-out 0s;
  text-decoration: none;
}

.doller-btn-two {
  width: 100%;
  background: #fff;
  border: 1px solid #fff;
  color: black;
  padding: 14px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  pointer-events: none;
  opacity: 0.7;
}

.doller-btn-two:hover {
  color: #fff;
  background: transparent;
  transition: 0.3s all ease-in-out 0s;
  text-decoration: none;
}

.all-data-pricing.secound-doller {
  background-color: black;
}

span.mont {
  font-size: 20px;
  color: #f5f6f4cc;
  margin-left: 6px;
  font-weight: 600;
}

.iner-two p,
.icon-box-two h3 {
  color: #d8d8d8;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.iner p,
.iner-two p {
  margin-bottom: 20px;
  margin-top: 40px;
  color: #d8d8d8;
  font-size: 16px;
}

.icon-box h3,
.icon-box-two h3 {
  margin: 15px auto;
}

.all-data-pricing.premium {
  background: #191a1f;
}

.doller .icon-box h3 {
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  text-transform: none;
}

.premium .doller .icon-box h3 {
  color: #fff;
}

.all-data-pricing button {
  cursor: pointer;
  transition: 0.3s all ease-in-out 0s;
}

.price-service-wrap {
  background: #500543;
  padding: 100px 0;
  color: #fff;
  border-radius: 80px;
}

.price-service-container,
.ready-collaborate-container,
.why-ukreate-container,
.three-level-container {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
}

.service-cont-img {
  display: flex;
  gap: 40px;
  align-items: center;
}

.price-service-icon-list {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px 0;
}

.price-service-icon-list:not(:last-child) {
  border-bottom: 1px solid #f5f6f426;
}

.price-service-cont-wrap {
  max-width: 500px;
}

.price-service-cont-wrap h2 {
  max-width: 470px;
  font-size: 48px;
  line-height: 59px;
  margin: 0 0 16px;
  padding: 0;
}

.price-service-cont-wrap p {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  padding: 0;
}

.price-service-list-wrap {
  margin-top: 36px;
}

.price-service-icon-list p {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
}

.price-service-label {
  margin: 0;
  display: inline-block;
  background: #ffffff1a;
  padding: 12px 16px;
  border-radius: 100px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  top: -24px;
}

.price-how-work {
  background: #601d54;
  padding: 0px 32px 40px;
  border-radius: 32px;
  margin-top: 120px;
  text-align: center;
}

.price-how-work p {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
}

.price-how-work a {
  padding: 16px 24px;
  margin: 48px auto 0;
  border-radius: 100px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  transition: 0.3s all ease-in-out;
}

.price-how-work a:hover {
  background: #000;
  color: #fff;
  text-decoration: none;
}

.price-how-work a:hover span img {
  filter: invert(1);
}

/* Why Choose Ukreate Section */
.why-ukreate-sec {
  padding: 100px 0;
}

.why-ukreate-sec h2 {
  font-size: 48px;
  line-height: 56px;
  color: #151516;
  margin: 0;
  padding: 0;
}

.why-choose-card-wrap {
  margin-top: 56px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.why-choose-card {
  flex: 0 0 calc(33% - 8px);
  padding: 48px 32px;
  background: #f5f6f4;
  border-radius: 32px;
  text-align: center;
  transition: 0.3s all ease-in-out 0s;
}

.why-choose-card h3 {
  font-size: 20px;
  line-height: 24px;
  color: #151516;
  margin: 32px 0 8px;
  padding: 0;
}

.why-choose-card p {
  font-size: 16px;
  line-height: 22px;
  color: #151516;
  margin: 0;
}

.why-choose-card:hover img {
  transform: translateY(-20px);
  transition: 0.3s all ease-in-out 0s;
}

.why-choose-card img {
  transition: 0.3s all ease-in-out 0s;
}

/* Price Collaborate Section */
.ready-collaborate-sec {
  padding: 0;
}

.ready-collaborate-cards-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.ready-collaborate-cards-wrap .ready-collaborate-card {
  flex: 0 0 calc(50% - 8px);
  box-sizing: border-box;
  padding: 92px 50px;
  text-align: center;
  border-radius: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.first-coll-card {
  background: #f5f6f4;
}

.ready-collaborate-cards-wrap .ready-collaborate-card h2 {
  font-size: 48px;
  line-height: 56px;
}

.first-coll-card h2 {
  color: #191a1f;
  margin: 0;
  padding: 0;
}

.other-coll-card {
  color: #fff;
}

.ready-collaborate-card a {
  background: #f3f4f2d9;
  border: 0;
  padding: 8px 32px 8px 4px;
  border-radius: 48px;
  font-size: 16px;
  line-height: 20px;
  color: #191a1f;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: 0.3s all ease-in-out 0s;
}

.ready-collaborate-card a:hover {
  background: #fff;
  transition: 0.3s all ease-in-out 0s;
  text-decoration: none;
}

.ready-collaborate-card a:hover img {
  background: #f3f4f2d9;
}

.ready-collaborate-card a img {
  background: #fff;
  border-radius: 50%;
  width: 30px;
  height: 29px;
}

.ready-collaborate-card a:last-child {
  margin-top: 16px;
}

.prem-month-wrap {
  position: relative;
}

.premium .month {
  filter: blur(10px);
}

p.coming-soon-text {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
}

/* End Pricing Page */

/* Start Rewards Page */

.Amazing-main {
  padding: 80px 0 100px;
}

.Amazing-head h2 {
  color: #191a1f;
  font-size: 48px;
  font-weight: 800;
  text-align: center;
  margin: 0;
  padding: 0;
}

.Amazing-one {
  background: #f3f4f2;
  border-radius: 25px;
  padding: 10px;
  width: calc(33% - 20px);
}

.ped-amazing-img {
  width: 100%;
}

.Amazing-content {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  width: 100%;
  margin-top: 56px;
}

.Amazing-one h2 {
  color: #191a1f;
  font-size: 20px;
  font-weight: 600;
  margin: 24px 0 0;
  text-align: center;
  line-height: 24px;
  padding: 0;
}

.Amazing-one p {
  color: #191a1f;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  margin: 8px 5px 0;
  text-align: center;
  padding: 0;
}

.doller.level-list {
  margin-bottom: 14px;
}

.icon-box-Amazing h3 {
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: 20px;
}

.Amazing-one button {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 28px 0px;
  background: transparent;
  color: #191a1f;
  font-size: 16px;
  font-weight: 500;
  gap: 13px;
}

.eduction-doller {
  padding: 40px 20px;
}

.Amazing-one button:hover {
  text-decoration: underline;
  transition: 0.3s all ease-in-out 0s;
  transform: scale(1.2);
}

.Amazing-one button {
  padding: 12px 24px;
  border: 0;
  margin-bottom: 24px;
  cursor: pointer;
  transition: 0.3s all ease-in-out 0s;
}

/* Influencer Section */
.main_wrap {
  background: #f3f4f2;
  border-radius: 80px;
  padding: 100px 0px;
}

.main_wrap.leader-wrap {
  background: transparent;
  border-radius: 0;
}

.Influencer {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: center;
}

.Infl-text h2 {
  font-size: 48px;
  color: #191a1f;
  font-weight: 800;
  line-height: 56px;
  margin: 0;
  padding: 0;
}

.Infl-text p {
  color: #191a1f;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  margin: 16px 0 0;
  padding: 0;
}

.Infl-reward {
  display: flex;
  gap: 20px;
  margin-top: 65px;
}

.req-icon {
  background: #0000000d;
  padding: 20px;
  border-radius: 20px;
}

.gredient {
  background: linear-gradient(45deg, #986ff033, #ff827e33, #ffc24f33) !important;
}

.Requirements,
.Requirements-two {
  background: #fff;
  padding: 10px;
  border-radius: 29px;
  width: 50%;
}

.main_wrap.leader-wrap .Requirements,
.main_wrap.leader-wrap .Requirements-two {
  background: #f3f4f2;
}

.req-icon h2 {
  color: #191a1f;
  font-size: 28px;
  font-weight: 500;
  margin: 16px 0 0;
  line-height: 32px;
  padding: 0;
}

.contect-list-wrap {
  padding: 8px 24px 24px;
}

.contect {
  display: flex;
  gap: 18px;
  padding: 16px 0;
}

.contect:not(:last-child) {
  border-bottom: 1px solid #00000017;
}

.contect .icon-box h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.contect .icon-box p {
  color: #191a1f;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  padding: 0;
  margin: 0;
}

.brand {
  border-bottom: unset !important;
}

/* Join Kreate Section */
.join-kreator-wrap {
  background: #500543;
  padding: 112px 0;
  margin: 32px;
  text-align: center;
  color: #fff;
  border-radius: 64px;
}

.kreator-cont-wrap h2 {
  font-size: 48px;
  line-height: 56px;
  margin: 0;
  padding: 0;
}

.kreator-cont-wrap p {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  padding: 24px 0 56px;
}

.kreator-cont-wrap a {
  color: #191a1f;
  font-size: 16px;
  line-height: 20px;
  padding: 8px 32px 8px 4px;
  background: #f3f4f2d9;
  border: none;
  border-radius: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 10px;
  cursor: pointer;
  transition: 0.3s all ease-in-out 0s;
}

.kreator-cont-wrap a:hover {
  background: #fff;
  transition: 0.3s all ease-in-out 0s;
}

.kreator-cont-wrap a:hover span {
  background: #f3f4f2d9;
}

.kreator-cont-wrap a span {
  width: 35px;
  height: 35px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* End Rewards Page */

/* Start Accept Policy Page */

.final_accpet-policy-wrap {
  background: var(--backgroundColor);
  padding: 64px 0;
}

.accept-policy-head-wrap {
  text-align: center;
  padding-bottom: 64px;
}

.accept-policy-cont-wrap:not(:last-child) {
  margin-bottom: 32px;
}

.accept-policy-cont-wrap {
  padding: 0 32px;
}

.accept-policy-head-wrap h1 {
  font-size: 40px;
  line-height: 50px;
  margin: 0;
  font-weight: 700;
}

.accept-policy-head-wrap p {
  font-size: 18px;
  margin: 12px 0 0;
  padding: 5px 0;
  color: #4a4a4f;
}

.accept-policy-cont-wrap p {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  padding: 5px 0 3px;
  color: #4a4a4f;
}

.accept-policy-cont-wrap h2 {
  font-size: 24px;
  margin: 0;
  padding-bottom: 7px;
  line-height: 34px;
}

/* End Accept Policy Page */

/* Start Resource Page News */

.tabs .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2 .uc-gallery--cards {
  display: flex !important;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  margin: 0;
  margin-top: 50px;
}
.tabs .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2 .uc-gallery--cards .uc-gallery--card {
  background: #f3f4f2;
  border-radius: 20px !important;
  box-shadow: none !important;
  flex-basis: calc(33% - 10px);
  flex-grow: 0 !important;
  flex-shrink: 0;
  font-size: 14px !important;
  max-width: none;
  padding: 10px !important;
  width: auto !important;
  margin: 0 !important;
}
.tabs .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2 .uc-gallery--cards .uc-gallery--headline {
  color: #000;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  margin-bottom: 16px !important;
  margin-top: 0 !important;
  padding: 5px 0 3px !important;
  max-height: unset;
}
.tabs .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2 .uc-gallery--cards .uc-gallery--date,
.tabs .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2 .uc-gallery--cards .uc-gallery--source {
  color: #4a4a4f !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
}
.tabs .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2 .uc-gallery--cards .uc-gallery--summary {
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 20px;
  margin: 16px 0 0 !important;
}
.tabs .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2 .uc-gallery--cards .uc-gallery--image {
  border-radius: 20px !important;
}

/* End Resource Page News */

/* Start media query */
@media only screen and (min-width: 1365px) {
  .chat_one_left {
    margin-left: -82px;
  }

  .chat_one_lef.commitment-card.boder_bt {
    margin-left: 80px;
  }

  .service-cont-img > div {
    flex: 0 0 calc(50% - 30px);
  }
}

@media (max-width: 1365px) {
  .price-service-container,
  .ready-collaborate-container,
  .why-ukreate-container,
  .three-level-container {
    max-width: unset;
    padding: 0 60px;
  }

  .service-cont-img {
    justify-content: space-between;
  }

  .price-service-img-wrap {
    text-align: center;
  }

  .ready-collaborate-cards-wrap .ready-collaborate-card {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .service-cont-img > div {
    flex: 0 0 calc(50% - 30px);
  }
}

@media only screen and (max-width: 1250px) {
  .main_wrap {
    padding: 80px 0px;
  }
}

@media (max-width: 1200px) {
  .tabtop {
    font-size: 14px;
    padding: 14px 20px;
  }

  .svg {
    flex: 0 0 22px;
  }

  .service-cont-img {
    justify-content: space-between;
    align-items: center;
  }

  .price-service-img-wrap img {
    width: 100%;
  }

  .Amazing-main {
    padding: 80px 0;
  }
}

@media (max-width: 1024px) {
  .bannerWrap {
    margin-bottom: 0;
  }

  .aboutBannerTitle,
  .whyExitTitle,
  .commi_sec h2,
  .final_ftr-text h2,
  .Resources-wrapper .heading h2,
  .pricing-banner-cont-wrap h1,
  .price-service-cont-wrap h2,
  .why-ukreate-sec h2,
  .ready-collaborate-cards-wrap .ready-collaborate-card h2,
  .kreator-cont-wrap h2,
  .Amazing-head h2,
  .Infl-text h2 {
    font-size: 36px;
    line-height: 46px;
  }

  .aboutBannerTitle,
  .whyExitTitle,
  .commi_sec h2 {
    margin: 0 0 30px;
  }

  .whyExitText {
    font-size: 18px;
    line-height: 27px;
  }

  .showCaseCont {
    max-width: 100%;
  }

  .whyExitWrap,
  .rootMission,
  .founder-outer-wrap {
    padding: 50px 15px;
  }

  .main_wrapper.mobile-responsive {
    padding: 50px 0;
  }

  .missionContainer {
    padding: 0 15px;
  }

  .founder-wrap > div {
    width: 50%;
  }

  .founder-img-wrap img {
    width: 100%;
  }

  .main_wrapper {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .founder-follow-card {
    margin-top: 20px;
  }

  h2.founder-heading {
    margin: 20px 0;
  }

  .tabs-block {
    width: 60%;
  }

  .pricing-plan-container {
    padding: 80px 60px !important;
  }
}

@media only screen and (max-width: 1365px) and (min-width: 769px) {
  .all_data {
    margin-left: 0;
    gap: 50px;
  }

  .commitment-card {
    padding: 12px 22px;
    width: 200px;
  }

  .chat_one.commitment-card p,
  .btn_text p {
    font-size: 16px;
    margin: 6px 0px;
  }

  .main_wrapper {
    padding: 100px 20px;
  }
}

@media (max-width: 991px) {
  .Resources-wrapper.container {
    padding: 0;
  }

  .tabcontent-you h2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 60px;
    margin-top: 0;
  }

  .tabcontent-you h3 {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .date {
    flex-direction: column;
    gap: 5px;
  }

  .pricing-grid,
  .service-cont-img,
  .why-choose-card-wrap,
  .ready-collaborate-cards-wrap {
    flex-direction: column;
  }

  .all-data-pricing,
  .service-cont-img > div,
  .why-choose-card,
  .ready-collaborate-cards-wrap .ready-collaborate-card {
    width: 100%;
  }

  .ready-collaborate-sec,
  .why-ukreate-sec,
  .price-service-wrap {
    padding: 80px 0;
  }

  .Amazing-one {
    width: calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);
  }

  .Amazing-content {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .Infl-reward {
    flex-direction: column;
  }

  .Requirements,
  .Requirements-two {
    width: 100%;
  }

  .join-kreator-wrap {
    padding: 80px 0;
  }

  .join-kreator-wrap {
    padding-left: 30px;
    padding-right: 30px;
  }
  .accept-policy-head-wrap h1 {
    font-size: 32px;
    line-height: 42px;
  }

  .accept-policy-head-wrap p {
    font-size: 16px;
  }

  .accept-policy-head-wrap {
    padding-bottom: 40px;
  }

  .accept-policy-cont-wrap {
    padding: 0;
  }

  .accept-policy-cont-wrap p {
    font-size: 14px;
    line-height: 24px;
  }

  .accept-policy-cont-wrap h2 {
    font-size: 22px;
    line-height: 32px;
  }
  .tabs
    .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2
    .uc-gallery--cards
    .uc-gallery--meta.style-scope.upcontent-suggestion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .btn_text.commitment-card {
    width: 300px !important;
    padding: 30px 20px !important;
  }

  .chat_one.commitment-card p,
  .btn_text p,
  .Amazing-one p,
  .Infl-text p,
  .kreator-cont-wrap p {
    font-size: 14px;
  }

  .btn_text button {
    padding: 11px 40px;
    font-size: 14px;
  }

  .all_data {
    display: unset;
    margin-left: 0;
  }

  .commi_sec h2 {
    font-size: 30px;
    margin: 0;
  }

  .btn_text.commitment-card {
    margin: 0px !important;
  }

  .img_sec {
    display: none;
  }

  .commitment-card {
    padding: 16px 20px !important;
    width: 260px;
    position: relative;
    margin-bottom: 45px;
  }

  .main_wrapper {
    display: block;
  }

  .chat_two.commitment-card.boder_bt {
    margin-left: 117px;
    margin-top: 55px;
  }

  .commitment-card {
    padding: 16px 20px !important;
    width: 230px !important;
  }

  .mob_img {
    display: flex;
    justify-content: space-between;
  }

  .transpot {
    padding-right: 20px;
  }

  .chat_one_lef.commitment-card.boder_bt {
    width: 141px !important;
    margin-left: 28px !important;
  }

  .chat_one_righ.commitment-card.boder_bt,
  .chat_one_left.commitment-card {
    width: 132px !important;
  }

  .second-row {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
  }

  .bottom-provide {
    margin-top: 19%;
  }

  .chat_one.commitment-card {
    margin-left: 40px;
    width: 140px !important;
  }

  .chat_one_right.commitment-card {
    width: 65px !important;
    margin-left: 90px;
  }

  .education {
    padding-left: 20px;
  }

  .desktop_responsive {
    display: none;
  }

  .mobile-responsive {
    display: block;
  }

  .img_se.desk_reviw img {
    width: 70%;
    margin-top: 30px;
    margin-bottom: 80px;
    margin: 45px auto;
    display: flex;
  }

  .bottm-btn-bin {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 70px;
  }

  .bannerWrap {
    padding: 40px 18px 60px;
    margin: 0 15px;
    border-radius: 40px;
  }

  .aboutBannerTitle,
  .commi_sec h2,
  .final_ftr-text h2,
  .whyExitTitle,
  .Resources-wrapper .heading h2,
  .pricing-banner-cont-wrap h1,
  .price-service-cont-wrap h2,
  .why-ukreate-sec h2,
  .ready-collaborate-cards-wrap .ready-collaborate-card h2,
  .kreator-cont-wrap h2,
  .Amazing-head h2,
  .Infl-text h2 {
    font-size: 32px;
    line-height: 42px;
  }

  p.aboutLabel {
    margin: 0 0 15px;
  }

  .whyExitText,
  .Amazing-one h2 {
    font-size: 16px;
    line-height: 22px;
  }

  .missionIconContWrap,
  .Amazing-content {
    flex-direction: column;
  }

  .founder-wrap {
    flex-direction: column-reverse;
  }

  .founder-wrap > div {
    width: 100%;
  }

  .all-data {
    width: 49%;
    flex-basis: calc(50% - 10px);
  }

  .Resources-wrapper.container {
    padding: 0 20px;
  }

  .tabs-name {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .tabs-name .tablink {
    margin: 0;
  }

  .pricing-banner-cont-wrap h1 {
    margin: 0;
  }

  .pricing-banner-wrap {
    padding: 56px 0 0;
  }

  .tabs-block,
  .pricing-banner-cont-wrap {
    width: 100%;
  }

  .pricing-plan-container {
    padding: 56px 60px !important;
  }

  .price-service-wrap,
  .why-ukreate-sec,
  .Amazing-main,
  .learn-grow-wrap {
    padding: 56px 0;
  }

  .resourceLearnGrowWrap {
    flex-direction: column;
  }

  .resourceLearnGrowCard {
    width: 100%;
  }

  .ready-collaborate-sec {
    padding: 0;
  }

  .footer-text-wrap {
    padding-top: 56px;
  }

  .Influencer {
    display: unset;
  }

  .Infl-reward {
    display: unset;
  }

  .number-img img {
    width: 30%;
  }

  .Rewards-left,
  .Requirements {
    width: 100%;
  }

  .Infl-text {
    padding: 35px 0px;
  }

  .Requirements {
    margin-bottom: 30px;
  }

  .req-icon img {
    width: 8%;
  }

  .Amazing-one {
    width: 100%;
    flex: 0 0 100%;
  }

  .Amazing-content {
    margin-top: 32px;
  }

  .icon-box-Amazing h3 {
    font-size: 15px;
  }

  .learn-grow-container {
    padding: 0 20px;
  }
  .accept-policy-cont-wrap:not(:last-child) {
    margin-bottom: 22px;
  }
  .tabs .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2 .uc-gallery--cards .uc-gallery--date,
  .tabs .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2 .uc-gallery--cards .uc-gallery--source {
    font-size: 14px !important;
  }
  .tabs .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2 .uc-gallery--cards .uc-gallery--card {
    flex-basis: calc(50% - 10px);
    width: 100% !important;
  }
}

@media (max-width: 580px) {
  .main_wrapper {
    border-radius: 40px;
  }

  .all-data {
    width: 100%;
    flex-basis: 100%;
  }

  .tabs-name {
    flex-wrap: wrap;
  }

  .pricing-plan-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .price-service-container,
  .ready-collaborate-container,
  .why-ukreate-container,
  .three-level-container {
    padding: 0 20px;
  }

  .req-icon h2 {
    font-size: 22px;
    line-height: 27px;
  }

  .main_wrap {
    padding: 56px 0;
    border-radius: 40px;
  }

  .price-service-wrap {
    border-radius: 40px;
  }

  .pricing-banner-wrap {
    margin: 0 20px;
  }

  .tabtop {
    padding: 14px 5px;
  }

  .price-service-cont-wrap p,
  .contect .icon-box p {
    font-size: 14px;
    line-height: 18px;
  }

  .price-service-icon-list {
    flex-direction: column;
    align-items: start;
  }

  .price-service-icon-list p,
  .contect .icon-box h3 {
    font-size: 16px;
    line-height: 20px;
  }

  .doller .icon-box h3,
  .all-data-pricing button,
  .price-how-work a,
  .ready-collaborate-card button {
    font-size: 15px;
    line-height: 18px;
  }

  .month span {
    font-size: 32px;
    line-height: 48px;
  }

  h3.top-head,
  .price-how-work p {
    font-size: 16px;
    line-height: 20px;
  }

  .price-service-label {
    font-size: 13px;
    line-height: 18px;
  }

  .price-how-work {
    margin-top: 56px;
  }

  .why-ukreate-container h2 {
    max-width: 300px;
  }

  .why-choose-card-wrap {
    margin-top: 32px;
  }

  .all-data-pricing {
    padding: 20px;
  }

  .join-kreator-wrap {
    margin: 20px;
    padding: 60px 24px;
    border-radius: 40px;
  }
  .tabs .uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2 .uc-gallery--cards .uc-gallery--card {
    flex-basis: 100%;
    width: 100% !important;
  }
}

@media only screen and (max-width: 424px) {
  .chat_two.commitment-card.boder_bt {
    margin-left: 31px;
    margin-top: 40px;
    margin-bottom: 70px;
  }

  .transpot-img img {
    width: 80%;
  }

  .chat_one.commitment-card {
    margin-left: 0px;
    width: 110px !important;
  }

  .chat_one_right.commitment-card {
    margin-left: 35px;
  }

  .chat_one_left.commitment-card {
    width: 128px !important;
  }

  .btn_text.commitment-card {
    width: 240px !important;
    padding: 22px 20px !important;
  }

  .btn_text button {
    padding: 9px 30px;
    font-size: 13px;
  }

  .bottom-provide img {
    width: 70% !important;
    margin-left: 40px;
  }

  .final_ftr-text h2,
  .Resources-wrapper .heading h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .Resources-wrapper .heading h2 {
    text-align: center;
  }

  .price-service-cont-wrap {
    max-width: 350px;
  }

  .req-icon img {
    width: 12%;
  }
}
