@import '../../../styles/customMediaQueries.css';
.root {
  background-color: #ffff;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  gap: 1rem;
  padding: 120px 0;

  @media (--viewportXLarge) {
    max-width: var(--contentLgMaxWidthPages);
  }

  @media (--viewportMedium) {
    flex-direction: row;
  }

  @media (--viewportMediumScreen) {
    max-width: 100%;
    padding: 80px 60px;
  }

  @media (--viewportXSScreen) {
    padding: 56px 20px;
  }
}
.footerCopyRight {
  display: flex;
  justify-content: space-between;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  padding: 24px 10px;
  border-top: 1px solid #191a1f1a;
  @media (--viewportMedium) {
    padding: 32px 0;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentLgMaxWidthPages);
  }

  @media (--viewportMediumScreen) {
    max-width: 100%;
    padding: 24px 60px;
  }

  @media (--viewportFinalSmallScreen) {
    padding: 24px 20px;
    flex-direction: column-reverse;
    gap: 24px;
  }
}
.icon {
  font-size: 24px;
  width: 31px;
  height: 31px;
  margin-right: 0.25rem;
  color: white;
  background: linear-gradient(90deg, rgba(248, 49, 148, 1) 0%, #feaf64 100%);
  border-radius: 20px;
  padding: 0.25rem;
}
.linkText {
  color: #191a1f80;
  font-size: 16px;
  line-height: 20px;
}
.contactItem {
  margin-bottom: 1rem;
}
.text {
  width: 100%;
  font-size: 20px;
  line-height: 24px;
  color: #191a1f;
  font-weight: 500;
  margin: 0;
  @media (--viewportMedium) {
    width: 301px;
    margin-left: 1rem;
  }

  @media (--viewportMSScreen) {
    font-size: 16px;
    width: 100%;
  }
}
.logo {
  height: 59px;
  width: auto;
  object-fit: cover;
  @media (--viewportMedium) {
    width: 257px;
  }

  @media (--viewportMSScreen) {
    width: 217px;
  }
}

.copyRight {
  font-size: 14px;
  line-height: 22px;
  color: #191a1f;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  @media (--viewportMedium) {
    font-size: 16px;
  }

  @media (--viewportFinalSmallScreen) {
    flex-direction: column;
    align-items: start;
    gap: 15px;
  }
}

.footerTitle {
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: #191a1f;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.footerTitleWrap {
  margin: 0 0 32px;

  @media (--viewportFinalSmallScreen) {
    margin: 0 0 24pxpx;
  }
}

.footerNavLinksWrap,
.left {
  display: flex;
  flex-direction: column;
}

.footerNavLinksWrap {
  gap: 20px;
}

.left {
  gap: 35px;

  @media (--viewportFinalSmallScreen) {
    gap: 16px;
    margin-bottom: 32px;
  }
}

.footerNavLink,
.link {
  font-size: 16px;
  line-height: 20px;
  color: #191a1f80;
  font-weight: 500;
}

.contactItem {
  display: flex;
  gap: 12px;
  align-items: center;
}

.instaWrap {
  display: flex;
  gap: 16px;
  border-radius: 20px;
  background-color: #f3f4f2;
  align-items: center;
  padding: 6px 14px 6px 14px;

  @media (--viewportFinalSmallScreen) {
    margin-top: 32px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.instaIcon img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.left {
  width: 50%;

  @media (--viewportMSScreen) {
    width: 33%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.footerMidCont,
.right {
  width: 25%;

  @media (--viewportMSScreen) {
    width: 33%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.followText {
  font-size: 14px;
  line-height: 20px;
  color: #191a1f80;
  font-weight: 500;
  margin: 0;
}

.instaContWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.svgIcon {
  @media (--viewportFinalSmallScreen) {
    display: none;
  }
}

.instaContIconLink {
  display: inline-block;
  width: 100%;
}

.socialWrap {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 10px;
}

.socialIconLink {
  display: inline-block;
  width: 25px;
  height: 25px;
}

.socialIconImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.5;
}
