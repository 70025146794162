@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;
  padding: var(--modalPadding);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 1;
    min-height: max(100%, 100vh);
    height: auto;
    padding: 24px;
    background-color: var(--colorWhite);
    border-bottom: none;
  }
}

.modalContent {
  composes: marketplaceModalContentStyles from global;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 24px;
  margin-bottom: 16px;
  color: var(--marketplaceColor);
}

.message {
  composes: marketplaceModalParagraphStyles from global;
  margin-bottom: 24px;
}

.button {
  composes: buttonPrimary from global;
  margin-top: 16px;
}
